<template>
  <standard-page title="Claim Requests" :definition="$DEFINITIONS.claimRequests">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'identities-home'}">Identifier</router-link>
      </span>
      <span>Claim Requests</span>
    </template>
    <template v-slot:content>
      <data-table :api="api" :columns="columns" :row-click="view" list-key="dids"></data-table>
    </template>
  </standard-page>
</template>
<script>
export default {
  name: "VerifierDids",
  data() {
    return {
      api: `${this.$identity.defaults.baseURL}/identity/dids/authorizations?permission=Consumer`,
      columns: [
        { title: 'Catalog', data: 'catalog_name' },
        { type: 'did', data: 'did.did' },
        { title: 'Name', data: 'did', render: data => data.short_name ? data.short_name : '' },
        { title: 'Claims', data: 'claims_count', orderable: false },
        { title: 'Attestations', data: 'attestations_count', orderable: false },
        { title: 'Awaiting Attestation', data: 'awaiting_attestation', orderable: false },

      ],
    };
  },
  methods: {

    view(data) {
      this.$router.push({
        name: 'view-requested-did', params: {
          catalog: data.did.catalog,
          did: data.did.did,
          short_name: data.did.short_name
        }
      });
    },

  }
}
</script>

<style scoped>
.masked {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>